const proyecto = [
    {id: 1,vURL: "assets/img/proyectos/31OCT.mp4"},
    {id: 2,vURL: "assets/img/proyectos/24NOV.mp4"},
    {id: 3,vURL: "assets/img/proyectos/12DIC.mp4"},
    {id: 4,vURL: "assets/img/proyectos/16ENE.mp4"},
    {id: 5,vURL: "assets/img/proyectos/16FEB.mp4"},
    {id: 6,vURL: "assets/img/proyectos/15MAR.mp4"},
    {id: 7,vURL: "assets/img/proyectos/5ABR.mp4"},
    {id: 8,vURL: "assets/img/proyectos/14JUN.mp4"},
    {id: 9,vURL: "assets/img/proyectos/12JUL.mp4"},
    {id: 10,vURL: "assets/img/proyectos/20AGO.mp4"},
    {id: 11,vURL: "assets/img/proyectos/27SEP.mp4"},
    {id: 12,vURL: "assets/img/proyectos/18OCT.mp4"},
    {id: 13,vURL: "assets/img/proyectos/15NOV.mp4"},
    {id: 14,vURL: "assets/img/proyectos/20DIC.mp4"},
    {id: 15,vURL: "assets/img/proyectos/07ENE.mp4"},
    {id: 16,vURL: "assets/img/proyectos/21FEB.mp4"},
    {id: 17,vURL: "assets/img/proyectos/25MAR.mp4"}
  ];
  
  export default proyecto;